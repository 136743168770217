<template>
  <div :class="`user-container container-fluid ` + (usertype === 'plus' ? 'mt--6' : 'mt--5')">
    <div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">
          <div class="card-header">
            <h3 class="mb-0">
              User Management
              <a
                href="javascript:void(0)"
                style="float: right"
                class="btn btn-success"
                @click="addUser()"
                >Add User</a
              >
            </h3>
          </div>

          <div class="card-body">
            <el-table
              :data="tableData"
              border
              style="width: 100%"
              size="mini"
              v-loading="loading"
            >
              <el-table-column prop="username" label="User Name" width="180">
              </el-table-column>
              <el-table-column prop="name" label="Name" width="180">
              </el-table-column>
              <el-table-column prop="email" label="Email"> </el-table-column>
              <el-table-column prop="role" label="Role"> </el-table-column>
              <el-table-column
                prop="uid"
                label="Action"
                align="center"
                width="350"
              >
                <template slot-scope="scope">
                  <el-button
                    type="warning"
                    size="mini"
                    @click="editUser(scope.row.uid)"
                    >Edit</el-button
                  >
                   <el-button
                      type="info"
                      class="btn-mini"
                      @click="resetPin(scope.row)"
                      >Reset PIN</el-button
                    >

                    <el-button
                      type="info"
                      class="btn-mini"
                      @click="resetPassword(scope.row.uid)"
                      >Reset Password</el-button
                    >

                  <el-button
                    type="danger"
                    size="mini"
                    @click="deleteUser(scope.row)"
                    >Delete</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <add-user ref="adduser" v-on:submited="loadUser"/>
    <edit-user ref="edituser" v-on:submited="loadUser"/>
    <el-dialog
      title="Send reset password to customer"
      :visible.sync="dialogPasswordReset"
      width="50%"
    >
      <el-form
        label-width="100px"
        ref="formResetPassword"
        :model="formResetPassword"
      >
        <el-form-item label="Name">
          <el-input
            v-model="formResetPassword.name"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="User Name" prop="username">
          <el-input
            v-model="formResetPassword.username"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input
            v-model="formResetPassword.email"
            class="form-control-alternative"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPasswordReset = false">Cancel</el-button>
        <el-button
          type="primary"
          v-loading="submitingResetPassword"
          @click="sendResetPassword()"
          >Submit</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddUser from "./components/Add";
import EditUser from "./components/Edit";
import { getUserLists, getUser,  deleteUser, postResetPin, forgotPassword } from "@/api/user";
export default {
  components: {
    AddUser,
    EditUser,
  },
  computed: {
    plusuid() {
      return this.$store.getters.plusuid;
    },
    usertype(){
      return this.$store.getters.usertype;
    }
  },
  data() {
    return {
      loading: false,
      tableData: [],
      dialogPasswordReset: false,
      submitingResetPassword: false,
      formResetPassword: {
        id: "",
        name: "",
        username: "",
        email: "",
      },
    };
  },
  methods: {
    loadUser() {
      this.loading = true;
      getUserLists(this.plusuid).then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.tableData = data;
        }
      });
    },
    addUser() {
      this.$refs.adduser.show();
    },
    editUser(uid) {
      this.$refs.edituser.show(uid);
    },
    deleteUser(data) {
      this.$swal({
          title: 'Confirmation',
          html: `This will Remove username: ${data.username} role: ${data.role}. Continue?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Comfirm",
          cancelButtonText: 'Cancel',
          showCloseButton: true,
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            this.confirmDeleteUser(data.uid)
          } else {
            this.$message(`Action Canceled`); 
          }
        })
    },
    confirmDeleteUser(uid){
      deleteUser(uid).then(({result, message})=>{
        if (result) {
          this.$swal(`Message`, `Delete User is success`, "success").then(
            () => {
              this.loadUser()
            }
          );
        } else {
          this.$swal("Message", message, "error");
        }
      })
    },
    resetPassword(uid) {
      const loading = this.$notifyloading("Loading...");
      getUser(uid).then(({ result, data }) => {
        loading.close();
        if (result) {
          this.formResetPassword.id = data.id;
          this.formResetPassword.name = data.name;
          this.formResetPassword.username = data.username;
          this.formResetPassword.email = data.email;
          this.dialogPasswordReset = true;
        }
      });
    },
    sendResetPassword() {
      this.submitingResetPassword = true;
      const formData = {
        email: this.formResetPassword.email,
        forgot_user: true,
        forgot_password: true,
      };
      forgotPassword(formData).then(
        ({ result, message }) => {
          this.dialogPasswordReset = false;
          this.submitingResetPassword = false;

          if (result) {
            this.$refs.formResetPassword.resetFields();
            this.$swal(`Message`, message, "success");
          } else {
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    resetPin(data){
      this.$swal({
          title: 'Reset Passcode',
          html: `This will reset passcode in application of <b>${data.name}</b>. Continue?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Comfirm",
          cancelButtonText: 'Cancel',
          showCloseButton: true,
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            postResetPin(data.uid, {resetpin: true}).then(({ result, message }) => {
              if (result) {
                  this.$swal(`Message`, message, "success");
                } else {
                  this.$swal("Message", message, "error");
                }
            })
           } else {
            this.$message(`Remove payee ${data.name} Canceled`); 
          }
        });
    },
  },
  created() {
    this.loadUser();
  },
};
</script>

<style>
</style>